<template>
    <section class="invoice-preview-wrapper">
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row>
                <b-col cols="12" md="8">
                    <b-card no-body class="invoice-actions">
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" md="3">
                                    <b-form-group label="Fecha Salida Inicio" label-for="date_start">
                                        <flat-pickr v-model="filter.date_start" id="date_start"
                                            class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                            placeholder="DD/MM/YYYY" />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="3">
                                    <b-form-group label="Fecha Salida Fin" label-for="date_start">
                                        <flat-pickr v-model="filter.date_end" id="date_end"
                                            class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                            placeholder="DD/MM/YYYY" />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="4" class="mt-2">
                                    <b-button :disabled="loading" @click="getArticulosDoctosDet" size="sm"
                                        variant="primary" class="mr-1">
                                        <feather-icon icon="SearchIcon" /> Buscar
                                    </b-button>

                                    <b-button size="sm" v-if="rows.length > 0" @click="printReport" variant="secondary"
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                                        <feather-icon icon="PrinterIcon" />
                                        Imprimir
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>

            <b-row v-if="rows.length > 0">
                <b-col cols="12" md="12">
                    <b-card no-body class="mb-0">
                        <div class="table-responsive">
                            <div class="position-relative b-table-responsive mb-0 text-small small table-responsive"
                            :class="`${rows.length > 10 ? 'b-table-sticky-header' : ''}`">
                                <table ref="entregas" class="table b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">                                    
                                    <thead>
                                        <tr>
                                            <th rowspan="2" class="text-center align-middle">
                                                MATERIAL
                                            </th>
                                            <th class="text-center align-middle" v-for="(item, index) in choferes" :key="index">{{ item.label }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in articles" :key="index" v-if="item.linea.length > 1">
                                            <th>{{ item.linea }}</th>
                                            <td class="text-center" v-for="chofer in choferes" :key="chofer.id">
                                                {{ formatCurrency(item[chofer.id] ? item[chofer.id].totalUnidades : 0) }}
                                            </td>
                                        </tr>                                        
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total Importes</th>
                                            <th class="text-center" v-for="chofer in choferes" :key="chofer.id">
                                                {{ formatCurrency(calculateTotalForChofer(chofer.id)) }}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th># DOCUMENTOS</th>
                                            <th class="text-center" v-for="chofer in choferes" :key="chofer.id">
                                                {{ calculateTotalDocumentsForChofer(chofer.id) }}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>

        </b-overlay>
    </section>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BTable,
    BTableLite,
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BFormSelect,
    BBadge,
    BFormRadioGroup,
    BPagination,
    BTooltip,
    VBTooltip,

} from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex';
import moment from 'moment';
import * as XLSX from 'xlsx';

import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
const DeliveryRoutesResource = new DeliveryRoutesProvider();

import WebOperadoresProvider from '@/providers/WebOperadores';
const WebOperadoresResource = new WebOperadoresProvider();

import WebCamionesProvider from '@/providers/WebCamiones';
const WebCamionesResource = new WebCamionesProvider();

import ReportsProvider from '@/providers/Reports';
const ReportsResource = new ReportsProvider();

export default {
    name: "DeliveriesByDriver",
    components: {
        BCard,
        BCardBody,
        BCardHeader,
        BCardText,
        BTable,
        BTableLite,
        BOverlay,
        BSpinner,
        BRow,
        BCol,
        BButton,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormGroup,
        BFormSelect,
        BBadge,
        BFormRadioGroup,
        BPagination,
        BTooltip,
        VBTooltip,

        //
        flatPickr,
        vSelect,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            getWareHouses: 'auth/getWareHouses',
            logisticSetting: 'auth/getLogisticSetting',
        }),
        showColumnOrigin() {
            return this.findSetting('ACTIVAR_COLUMNA_ORIGEN_LOGISTICA') || false
        },
    },
    created() {

    },
    methods: {
        getTotalByChofer(choferId) {
            return this.articles.reduce((sum, item) => {
                return sum + (item[choferId] || 0);
            }, 0)
        },
        printReport() {
            const table = this.$refs.entregas;
            const wb = XLSX.utils.table_to_book(table);
            XLSX.writeFile(wb, 'Entregas-Chofer.xlsx');
        }
    },
    watch: {

    },
    setup() {

        const loading = ref(false)
        const search = ref(null)
        const choferes = ref([])
        const articles = ref([])
        const rows = ref([])

        const filter = ref({
            date_start: moment().startOf('month').format('YYYY-MM-DD'),
            date_end: moment().format('YYYY-MM-DD'),
            chofer_Id: null,
            camion_Id: null,
            option: 'fecha_factura',
        })

        const getChoferes = async () => {
            loading.value = true
            const { data } = await WebOperadoresResource.index({})
            loading.value = false
            if (data.isSuccesful && data.data) {
                choferes.value = data.data.filter(c => c.tipo === 'Chofer').map(i => {
                    return {
                        id: i.id,
                        label: i.nombre + ' ' + i.apellidos
                    }
                })
                choferes.value = _.orderBy(choferes.value, ['label'], ['asc']) 
            }
        }

        const __groupByLineaAndChofer = (data, choferes) => {
            const groupedData = {};

            data.forEach(item => {
                const linea = item.linea;
                const choferId = item.chofer_Id;
                const unidades = item.precio_Total_Neto;

                if (!groupedData[linea]) {
                    groupedData[linea] = {};
                }

                if (!groupedData[linea][choferId]) {
                    groupedData[linea][choferId] = 0;
                }

                groupedData[linea][choferId] += unidades;
            });

            // Ordenar las líneas alfabéticamente y crear el array con el nombre de la línea y los datos por chofer
            const orderedGroupedData = Object.keys(groupedData)
                .sort()
                .map(linea => {
                    const choferData = choferes.reduce((acc, chofer) => {
                        acc[chofer.id] = groupedData[linea][chofer.id] || 0;
                        return acc;
                    }, {});

                    // Devuelve el nombre de la línea y los datos de los choferes
                    return {
                        linea,  // Aquí se mantiene el nombre de la línea
                        ...choferData
                    };
                });

            return orderedGroupedData;
        };


        const _groupByLineaAndChofer = (data, choferes) => {
            const groupedData = {};

            data.forEach(item => {
                const linea = item.linea;
                const choferId = item.chofer_Id;
                const unidades = item.precio_Total_Neto;

                if (!groupedData[linea]) {
                    groupedData[linea] = {};
                }

                if (!groupedData[linea][choferId]) {
                    groupedData[linea][choferId] = 0;
                }

                groupedData[linea][choferId] += unidades;
            });

            // Ordenar las líneas alfabéticamente
            const orderedGroupedData = Object.keys(groupedData)
                .sort()
                .map(linea => {
                    // Para cada línea, devolvemos un objeto que contenga la línea
                    // y la suma de los valores para cada chofer en orden.
                    const choferData = choferes.reduce((acc, chofer) => {
                        acc[chofer.id] = groupedData[linea][chofer.id] || 0;
                        return acc;
                    }, {});

                    return {
                        linea,
                        ...choferData
                    };
                });

            return orderedGroupedData;
        };

        const groupByLineaAndChofer = (data, choferes) => {
            const groupedData = {};

            data.forEach(item => {
                const linea = item.linea;          // Nombre de la línea
                const choferId = item.chofer_Id;   // ID del chofer
                const unidades = item.precio_Total_Neto; // Unidades a sumar
                const numDocs = item.num_Documentos; // Número del documento

                // Si la línea no existe, la inicializamos
                if (!groupedData[linea]) {
                    groupedData[linea] = {};
                }

                // Inicializamos el objeto del chofer en la línea si no existe
                if (!groupedData[linea][choferId]) {
                    groupedData[linea][choferId] = {
                        totalUnidades: 0,
                        totalDocumentos: 0,
                    };
                }

                // Sumamos las unidades por chofer y línea
                groupedData[linea][choferId].totalUnidades += unidades;
                groupedData[linea][choferId].totalDocumentos = numDocs;
            });

            // Ordenar las líneas alfabéticamente y crear el array con el nombre de la línea y los datos por chofer
            const orderedGroupedData = Object.keys(groupedData)
                .sort() // Ordenar las líneas alfabéticamente
                .map(linea => {
                    if (linea != undefined || linea != "") {
                        const choferData = choferes.reduce((acc, chofer) => {
                            acc[chofer.id] = {
                                totalUnidades: groupedData[linea][chofer.id]?.totalUnidades || 0,
                                totalDocumentos: groupedData[linea][chofer.id]?.totalDocumentos || 0 // Usar el tamaño del Set para obtener el conteo único
                            };
                            return acc;
                        }, {});

                        // Devuelve el nombre de la línea y los datos de los choferes
                        return {
                            linea,  // Aquí se mantiene el nombre de la línea
                            ...choferData
                        };
                    }
                });
            return orderedGroupedData;
        };

        const calculateTotalDocumentsForChofer = (choferId) => {
            const item = rows.value.filter(i => i.chofer_Id == choferId)
            if (item.length > 0) {
                return item[0].num_Documentos
            }
            return 0;
        };

        const calculateTotalForChofer = (choferId) => {
            return articles.value.reduce((total, item) => {
                return total + (item[choferId]?.totalUnidades || 0);
            }, 0);
        }

        const getArticulosDoctosDet = async () => {

            articles.value = []

            try {

                const query = {
                    fechaIni: filter.value.date_start,
                    fechaFin: filter.value.date_end,
                }

                loading.value = true
                const { data } = await ReportsResource.getArticulosDoctosDet(query)
                if (data.isSuccesful && data.data) {
                    rows.value = data.data
                    const items = data.data
                    const result = groupByLineaAndChofer(items, choferes.value)
                    articles.value = result
                }

            } catch (error) {
                console.log(error)
            } finally {
                loading.value = false
            }
        }

        onMounted( async () => {
            await getChoferes()
        })

        return {
            search,
            loading,
            filter,
            choferes,
            articles,
            rows,

            //
            calculateTotalDocumentsForChofer,
            calculateTotalForChofer,
            getArticulosDoctosDet,

        };
    }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@media print {

    // Global Styles
    body {
        background-color: transparent !important;
        font-size: 8pt !important;
    }

    nav.header-navbar {
        display: none;
    }

    .main-menu {
        display: none;
    }

    .header-navbar-shadow {
        display: none !important;
    }

    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }

    footer.footer {
        display: none;
    }

    .card {
        background-color: transparent;
        box-shadow: none;
    }

    .customizer-toggle {
        display: none !important;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {



        // Action Right Col
        .invoice-actions {
            display: none;
        }
    }
}
</style>


<style>
.flatpickr-small .flatpickr-input {
    /*font-size: 8px!important; /* Ajusta el tamaño del texto del input */
    padding: 5px;
    /* Ajusta el padding del input */
    /*width: 120px; /* Ajusta el ancho del input */
}

.flatpickr-input {
    /*width: 150px!important; /* Ajusta el ancho del input */
    height: 30px !important;
    /* Ajusta la altura del input */
    /*font-size: 7px!important; /* Ajusta el tamaño del texto del input */
}

.b-table-sticky-header {
    overflow-y: auto;
    /* max-height: 480px; */
    max-height: calc(100vh - 130px)!important;
}
</style>